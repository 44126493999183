import React from "react"
import Container from "../container"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Spin as Hamburger } from "hamburger-react"
import cn from "classnames"
import { CheckCircle, Download, ChevronDown } from "../icons"
import styles from "./header.module.scss"

export default function Header() {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <StaticQuery
      query={query}
      render={data => (
        <header className={cn(styles.header, isOpen ? styles.open : null)}>
          <Container className={styles.container}>
            <div className={styles.logoArea}>
              <Link to="/" className={styles.logo}>
                <Img
                  style={{ width: "inherit", height: "inherit" }}
                  fixed={data.logo.sharp.fixed}
                  alt="logo"
                />
              </Link>
              <div className={styles.extra}>
                <div className={styles.widget}>
                  <h4>
                    <CheckCircle />
                    Türkiye’nin önde gelen
                  </h4>
                  <p>bağlantı elemanları üreticisi</p>
                </div>
                <div className={styles.widget}>
                  <h4>
                    <CheckCircle />
                    İstanbul’da Özel
                  </h4>
                  <p>Civata & Perçin imalatı</p>
                </div>
              </div>
              <div className={styles.hamburger}>
                <Hamburger
                  rounded
                  toggled={isOpen}
                  toggle={setOpen}
                  label="Toggle menu"
                  distance="sm"
                />
              </div>
            </div>
            <div className={styles.menuContainer}>
              <nav className={styles.nav}>
                <ul className={styles.mainMenu}>
                  <li>
                    <Link to="/" activeClassName={styles.active}>
                      ANA SAYFA
                    </Link>
                  </li>
                  <li className={styles.hasSub}>
                    <Link to="#" activeClassName={styles.active}>
                      KURUMSAL
                      <ChevronDown />
                    </Link>
                    <ul className={styles.subMenu}>
                      <li>
                        <Link to="/hakkimizda" activeClassName={styles.active}>
                          Hakkımızda
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/vizyon-ve-misyonumuz"
                          activeClassName={styles.active}
                        >
                          Vizyon ve Misyonumuz
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/kalite-politikamiz"
                          activeClassName={styles.active}
                        >
                          Kalite Politikamız
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/urunler" activeClassName={styles.active}>
                      ÜRÜNLER
                    </Link>
                  </li>
                  <li>
                    <Link to="/uretim" activeClassName={styles.active}>
                      ÜRETİM
                    </Link>
                  </li>
                  <li>
                    <Link to="/haberler" activeClassName={styles.active}>
                      HABERLER
                    </Link>
                  </li>
                  <li>
                    <Link to="/iletisim" activeClassName={styles.active}>
                      İLETİŞİM
                    </Link>
                  </li>
                  <li className={styles.highlight}>
                    <Link to="/dokumanlar">
                      <Download />
                      DÖKÜMANLAR
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </Container>
        </header>
      )}
    />
  )
}

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      sharp: childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
