import React from "react"
import { Link } from "gatsby"
import Title from "../title"
import { MapPin, Phone, Mail } from "../icons"
import Container from "../container"
import { graphql, StaticQuery } from "gatsby"
import SocialIcons from "../social-icons"
import styles from "./footer.module.scss"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        haberler: allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/content/blog/**/*.md" } }
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 5
        ) {
          edges {
            node {
              frontmatter {
                slug
                title
                date(formatString: "MMMM DD, YYYY")
              }
              id
            }
          }
        }

        urunler: allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/content/products/**/*.md" } }
          limit: 8
          sort: { order: ASC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              frontmatter {
                slug
                title
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <footer className={styles.footer}>
          <Container className={styles.container}>
            <div className={styles.col}>
              <Title headingLevel="h4">HABERLER</Title>
              <div className={styles.blogPosts}>
                {data.haberler &&
                  data.haberler.edges.map(({ node }) => (
                    <Link to={`/${node.frontmatter.slug}`} key={node.id}>
                      <span>{node.frontmatter.title}</span>
                      <small>{node.frontmatter.date}</small>
                    </Link>
                  ))}
              </div>
            </div>
            <div className={styles.col}>
              <Title headingLevel="h4">ÜRÜNLERİMİZ</Title>
              <ul className={styles.menuWidget}>
                {data.urunler.edges.map(({ node }) => (
                  <li key={node.id}>
                    <Link to={`/urunler/${node.frontmatter.slug}`}>
                      {node.frontmatter.title}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to="/urunler">Tüm Ürünler</Link>
                </li>
              </ul>
            </div>
            <div className={styles.col}>
              <Title headingLevel="h4">İLETİŞİM BİLGİLERİ</Title>
              <div className={styles.contactWidget}>
                <div>
                  <MapPin />
                  <p>
                    Tem 34 Sanayi Sitesi 2. Etap 1573. Sk. Akçaburgaz Mah. B4
                    No: 4 Esenyurt/İstanbul
                  </p>
                </div>
                <div>
                  <Phone />
                  <p>
                    <span>+90 (212) 672 13 80</span>
                    <small>Pzt-Cmt, 9:00 - 18:00</small>
                  </p>
                </div>
                <div>
                  <Mail />
                  <p>
                    <span>info@ozelcivata.com</span>
                    <small>24 saat içinde cevaplıyoruz</small>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </footer>
        <div className={styles.copyright}>
          <Container className={styles.copyrightContainer}>
            <p>
              © {new Date().getFullYear()}. Her hakkı saklıdır. ozelcivata.com.
            </p>
            <SocialIcons />
          </Container>
        </div>
      </>
    )}
  />
)

export default Footer
