import React from "react"
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "../icons"
import cn from "classnames"
import styles from "./social-icons.module.scss"

export default function SocialIcons({ className }) {
  return (
    <ul className={cn(styles.socialLinks, className)}>
      <li>
        <a
          href="https://www.facebook.com/ozelcivata"
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <Facebook style={{ color: "#1877f2" }} />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/ozelcivata"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
        >
          <Twitter style={{ color: "#1da1f2" }} />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/%C3%B6zel-c%C4%B1vata-251262133/"
          target="_blank"
          rel="noopener noreferrer"
          title="LinkedIn"
        >
          <Linkedin style={{ color: "#007bb5" }} />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/ozelcivata/"
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
        >
          <Instagram style={{ color: "#c32aa3" }} />
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCXnW4mFYZN82Ht00VGdKmuA"
          target="_blank"
          rel="noopener noreferrer"
          title="Youtube"
        >
          <Youtube style={{ color: "#ff0000" }} />
        </a>
      </li>
    </ul>
  )
}
