import React from "react"
import Container from "../container"
import { MapPin, Phone } from "../icons"
import styles from "./top-bar.module.scss"

export default function TopBar() {
  return (
    <div className={styles.topBar}>
      <Container className={styles.container}>
        <div className={styles.contactInfo}>
          <a
            href="https://www.google.com/maps?ll=41.064392,28.665151&z=14&t=m&hl=tr-TR&gl=TR&mapclient=embed&cid=7673239999650593743"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MapPin />
            Tem 34 Sanayi Sitesi 2. Etap 1573. Sk. Akçaburgaz Mah. B4 No: 4
            Esenyurt/İstanbul
          </a>
          <a href="tel:+902126721380" target="_blank" rel="noopener noreferrer">
            <Phone />
            +90 (212) 672 13 80
          </a>
        </div>
      </Container>
    </div>
  )
}
